<template>
  <div class="doctorInfo_container">
    <div class="doctorInfo_banner">
      <div class="doctorInfo_info">
        <div class="doctorInfo_info_left">
          <img
            class="doctorInfo_avatar"
            :src="require('@/assets/img/avatar.png')"
          />
        </div>
        <div class="doctorInfo_info_right">
          <div class="doctorInfo_info_right_top">
            <div>
              <div class="name">{{ data.name }}/{{ data.name_pinyin }}</div>
              <div class="department">
                <span v-if="data.department">
                  {{ data.department }}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                <span v-if="false"
                  >志愿者编号：{{
                    data.sale_phone == "0001" || !data.sale_phone
                      ? "暂未绑定"
                      : data.sale_phone
                  }}</span
                >
              </div>
            </div>
            <div
              style="
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: end;
              "
            >
              <CustomButton class="CustomButton" @click="goToPersonalInfo"
                >完善个人信息</CustomButton
              >
              <div
                style="font-size: 12px; color: red; white-space: nowrap"
                v-if="
                  ([0, 2].includes(data.work_card_ocr_status) &&
                    data.doctor_work_id_card) ||
                  ([0, 2].includes(data.work_card_ocr_status) &&
                    data.professional_panoramic &&
                    data.professional_person)
                "
              >
                {{ material[data.work_card_ocr_status] }}
              </div>
              <div
                style="font-size: 12px; color: red; white-space: nowrap"
                v-if="
                  [0, 2].includes(data.id_card_ocr_status) &&
                  data.id_card_front &&
                  data.id_card_reverse &&
                  data.id_card_number &&
                  data.bank_name_point &&
                  data.bank_number &&
                  data.bank_province &&
                  data.bank_city &&
                  data.bank_name
                "
              >
                {{ idCard[data.id_card_ocr_status] }}
              </div>
            </div>
          </div>
          <div class="doctorInfo_info_right_bottom">
            <div class="hospital">
              <img :src="require('@/assets/img/hospital.png')" />{{
                data.hospital
              }}
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div
              class="province_city"
              v-if="data.province"
              style="white-space: nowrap"
            >
              <img :src="require('@/assets/img/location.png')" />{{
                data.province
              }}
              <span> &nbsp;&nbsp;&nbsp;&nbsp;{{ data.city }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="doctorInfo_meetInfo">
        <span>
          总会议数：
          <span class="global-color"> {{ data.all_count }}</span></span
        >
        <span>
          已预约：
          <span class="global-color"> {{ data.booked_count }}</span></span
        >
        <span>
          已召开：
          <span class="global-color"> {{ data.convened_count }}</span></span
        >
      </div>
    </div>

    <div class="doctorInfo_main">
      <div class="title">会议列表</div>
      <div class="info_tips" v-if="data?.meet_list?.length == 0">暂无数据</div>
      <ItemScroll v-else ref="ItemScroll" :isTop="true">
        <div class="doctorInfo_main_block">
          <meetItem
            v-for="(item, index) in data.meet_list"
            :key="index"
            :item="item"
            :type="0"
            :userInfo="userInfo"
            @getPoster="getPoster"
            >{{ index }}</meetItem
          >
        </div>
      </ItemScroll>
    </div>
    <div class="doctorInfo_foot">
      <div class="doctorInfo_foot_btn" @click="goToWeeklyHome">
        <img
          class="doctorInfo_foot_btn_icon"
          :src="require('@/assets/img/homing.png')"
          alt=""
        />
        <div>周刊首页</div>
      </div>

      <div class="doctorInfo_foot_btn" @click="outLogin">
        <img
          class="doctorInfo_foot_btn_icon"
          :src="require('@/assets/img/outLogin.png')"
          alt=""
        />
        <div>退出登录</div>
      </div>
    </div>
    <popupQR
      class="popupQR"
      :content="content"
      v-if="content.id"
      ref="popupQR"
      @getPoster="getPoster"
    >
    </popupQR>
  </div>
</template>

<script>
import CustomButton from "@/components/unit/CustomButton";
import meetItem from "./meetItem.vue";
import ItemScroll from "../../unit/ItemScroll.vue";
import weixin from "@/common/weixin";
import popupQR from "./popupQR.vue";
export default {
  name: "doctorInfo",
  components: { CustomButton, meetItem, ItemScroll, popupQR },
  data() {
    return {
      content: {},
      data: {},
      userInfo: {},
      search: {
        page: "",
        pagesize: "20",
      },
      material: ["专家职称资料审核中", "", "专家职称资料审核失败"],
      idCard: ["专家身份证审核中", "", "专家身份证审核失败"],
    };
  },
  created() {},
  mounted() {
    this.loadData();
    document.addEventListener("visibilitychange", this.visibilityInfo);
  },
  beforeUnmount() {
    console.log("我已经remove了");
    window.removeEventListener("visibilitychange", this.visibilityInfo);
  },
  methods: {
    visibilityInfo() {
      if (document.visibilityState === "hidden") {
        console.log("用户离开了当前页面");
      } else if (document.visibilityState === "visible") {
        this.data.meet_list = [];
        this.loadData();
      }
    },
    async loadData() {
      //salesUserHome
      let url = this.$tools.getURL(this.$urls.sale.userHome, {});
      await this.$axios
        .get(url)
        .then((res) => {
          this.data = res.data;
          this.userInfo = {
            work_card_ocr_status: res.data.work_card_ocr_status,
            first_complete: res.data.first_complete,
            military_hospital: this.data.military_hospital,
            professional_qualifications_code:
              this.data.professional_qualifications_code,
            id: res.data.id,
          };
          this.data.meet_list = this.data.meet_list || [];
          this.data.meet_list = this.data.meet_list.map((x) => {
            x["openid"] = this.data.openid;
            return x;
          });
          this.$nextTick(() => {
            setTimeout(() => {
              this.$refs.ItemScroll.initScroll();
            }, 1000);
          });
        })
        .catch(() => {});

      // this.$axios.get(this)
    },

    getPoster(item) {
      this.content = item;
    },

    pullingUp() {
      // this.search.page += 1;
      // this.loadData();
    },
    goToWeeklyHome() {
      this.$router.push({
        path: "/articleList",
      });
    },
    outLogin() {
      this.$store.dispatch("setItem", {
        userId: 0,
        userInfo: {},
      });
      this.$tools.setStorage("userLogin", {}, localStorage);
      this.$router.push({
        path: "/doctorLogin",
      });
    },
    goToPersonalInfo() {
      this.$router.push({
        path: "/personalInfo",
        query: {
          id: this.data.id,
          from: this.$route.path,
        },
      });
    },
  },
};
</script>

<style >
.doctorInfo_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
}
.doctorInfo_banner {
  background-image: url("../../../assets/img/banner.png");
  background-size: 100% 100%;
  height: 248px;
  width: 100%;
}
.doctorInfo_info {
  margin: 16px;
  display: flex;
  padding-bottom: 16px;
  border-bottom: 1px solid #756767;
}
.doctorInfo_avatar {
  width: 54px;
  height: 54px;
}

.doctorInfo_info_right {
  flex: 1 0 0;
  margin-left: 12px;
  color: #fff;
}
.doctorInfo_info_right_top {
  height: 50px;
  align-items: center;
  justify-content: space-between;
  display: flex;
}
.doctorInfo_info_right_top .name {
  font-weight: bold;
  font-size: 14px;
  line-height: 1.5;
}
.doctorInfo_info_right_top .department {
  font-size: 12px;
}
.doctorInfo_banner .CustomButton {
  width: 100px;
  height: 20px !important;
  font-size: 14px;
  padding: 0 !important;
  line-height: 20px;
}
.doctorInfo_info_right_bottom {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-top: 10px;
}
.doctorInfo_info_right_bottom img {
  width: 16px;
  margin-right: 4px;
}
.doctorInfo_meetInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #ffffff;
  padding: 0 38px;
}

.doctorInfo_main {
  margin: 0 16px;
  margin-top: -60px;
  border-radius: 10px;
  background: #fff;
  padding: 15px;
  flex: 1 0 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.doctorInfo_main .title {
  font-weight: bold;
  font-size: 14px;
  color: #f26649;
  margin-bottom: 10px;
}
.meetItem_container:last-child {
  border: none;
}
.doctorInfo_foot {
  flex: 50px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
}
.doctorInfo_foot_btn {
  font-size: 14px;
  color: #606060;
  line-height: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.doctorInfo_foot_btn_icon {
  width: 21px;
}
.info_tips {
  color: #ccc;
  font-size: 14px;
  flex: 1 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.doctorInfo_container .popup_main {
  overflow: auto;
  height: 100%;
  width: 100%;
  padding: 0 0 10px 0;
}
.doctorInfo_container .popup_title_text {
  font-size: 20px;
}
.doctorInfo_container .popub_close {
  right: 15px;
}
.doctorInfo_container .popub_close .el-icon-close {
  font-size: 30px;
}
</style>